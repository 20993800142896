import React, { useEffect, useState } from 'react';
import { Table, Pagination, Form, Spinner, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import { useRef } from 'react';

const InsiderTable = () => {
    const debouncedFetch = debounce((searchTerm) => fetchFilteredInsiders(searchTerm), 300);
    const [insiders, setInsiders] = useState([]);
    const [displayedInsiders, setDisplayedInsiders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [insidersPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const handleRowClick = (insider) => {
        history.push({
            pathname: `/insider/${insider.lc}`,
            state: { insider },
        });
    };

    const fetchInsiders = async () => {
        setIsLoading(true);
        const token = Cookies.get('authToken');
        const response = await axios.get('https://api.myinsiderposition.com/insiders', {
            headers: { Authorization: `Basic ${token}` },
        });
        const sortedInsiders = response.data.sort((a, b) => a.lastname.localeCompare(b.lastname));
        setInsiders(sortedInsiders);
        setDisplayedInsiders(sortedInsiders);
        setIsLoading(false);
    };

    const latestRequest = useRef();

    const fetchFilteredInsiders = async (searchTerm) => {
        setIsLoading(true);
        const token = Cookies.get('authToken');
        const currentRequest = axios.get(`https://api.myinsiderposition.com/insiders?search=${searchTerm}`, {
            headers: { Authorization: `Basic ${token}` },
        });
        latestRequest.current = currentRequest;

        try {
            const response = await currentRequest;
            // Ignore the response if it's not from the latest request
            if (currentRequest !== latestRequest.current) return;

            const sortedInsiders = response.data.sort((a, b) => a.lastname.localeCompare(b.lastname));
            setInsiders(sortedInsiders);
            setDisplayedInsiders(sortedInsiders);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInsiders();
    }, []);

    const indexOfLastInsider = currentPage * insidersPerPage;
    const indexOfFirstInsider = indexOfLastInsider - insidersPerPage;
    const currentInsiders = displayedInsiders.slice(indexOfFirstInsider, indexOfLastInsider);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        const searchTerm = e.target.value.replace(/\s+/g, '');
        if (searchTerm.length >= 1) {
            debouncedFetch(searchTerm);
        } else if (searchTerm === '') {
            fetchInsiders();
        }
    };

    const totalPages = Math.ceil(displayedInsiders.length / insidersPerPage);

    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
                {i}
            </Pagination.Item>,
        );
    }

    const downloadCSV = () => {
        const headers = ['Company', 'Ticker', 'Last Name', 'First Name', 'Birthdate', 'LC', 'Holding', 'Location', 'URL'];
        const csvContent = [
            headers.join(','),
            ...displayedInsiders.map((insider) =>
                [
                    insider.company,
                    insider.ticker,
                    insider.lastname,
                    insider.firstname,
                    insider.birthdate,
                    insider.lc,
                    insider.holding,
                    insider.location,
                    insider.url,
                ].join(','),
            ),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'insiders.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Form.Control
                type="text"
                placeholder="Search..."
                value={search}
                onChangeCapture={handleSearchChange}
                className="mb-3"
            />
            {isLoading ? (
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            ) : (
                <>
                    <Button variant="outline-primary" className="float-right mb-3" onClick={downloadCSV}>
                        Download CSV
                    </Button>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Ticker</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Birthdate</th>
                                <th>LC</th>
                                <th>Holding</th>
                                <th>Location</th>
                                <th>URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentInsiders.map((insider, index) => (
                                <tr key={insider.lc} onClick={() => handleRowClick(insider)} style={{ cursor: 'pointer' }}>
                                    <td>{insider.company}</td>
                                    <td>{insider.ticker}</td>
                                    <td>{insider.lastname}</td>
                                    <td>{insider.firstname}</td>
                                    <td>{insider.birthdate}</td>
                                    <td>{insider.lc}</td>
                                    <td>{insider.holding}</td>
                                    <td>{insider.location}</td>
                                    <td>{insider.url}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>{paginationItems}</Pagination>
                </>
            )}
        </div>
    );
};

export default InsiderTable;

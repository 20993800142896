import React from 'react';
import { Container } from 'react-bootstrap';
import InsiderTable from './InsiderTable';

const InsiderSearch = () => {
  return (
    <Container>
      <h2 className="text-center mt-4">Insider Search</h2>
      <InsiderTable />
    </Container>
  );
};

export default InsiderSearch;

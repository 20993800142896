import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './InsiderDetails.css';

const InsiderDetails = ({ location }) => {
    const history = useHistory();
    const { insider } = location.state;

    const handleBack = () => {
        history.push({
            pathname: `/insider-search`
        });
    };

    const getSearchUrlLinkedIn = () => `https://www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(insider.firstname)}%20${encodeURIComponent(insider.lastname)}%20${encodeURIComponent(insider.company)}&origin=GLOBAL_SEARCH_HEADER&sid=a2.`
    const getSearchUrlTelefonbuch = () => `https://www.dastelefonbuch.de/Personen/${encodeURIComponent(insider.firstname)}%20${encodeURIComponent(insider.lastname)}`

    return (
        <div className="insider-details-container">
            <Link to="/insider-search" className="insider-details-back">← Back</Link>
            <h1 className="insider-details-title">{insider.firstname} {insider.lastname}</h1>
            <p>Birthdate: {insider.birthdate}</p>
            <p>Company: {insider.company}</p>
            <p>LC: {insider.lc}</p>
            {(
                <p>
                    LinkedIn: <a href={getSearchUrlLinkedIn()} target="_blank" rel="noreferrer">{insider.firstname} {insider.lastname}</a>
                </p>
            )}
            {insider.facebook && (
                <p>
                    Facebook: <a href={insider.facebook} target="_blank" rel="noreferrer">Facebook Profile</a>
                </p>
            )}
            {insider.instagram && (
                <p>
                    Instagram: <a href={insider.instagram} target="_blank" rel="noreferrer">Instagram Profile</a>
                </p>
            )}
            <p>
                Additional link: <a href={getSearchUrlTelefonbuch()} target="_blank" rel="noreferrer">Telefonbuch.de</a>
            </p>
        </div>
    );
};

export default InsiderDetails;


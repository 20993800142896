import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Header = () => {
  const isAuthenticated = Cookies.get('authenticated');
  const userEmail = Cookies.get('userEmail');

  const handleLogout = () => {
    Cookies.remove('authenticated');
    Cookies.remove('authToken');
    Cookies.remove('userEmail');
    window.location.href = '/';
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/">
        My Insider Position
      </Navbar.Brand>
      {isAuthenticated && (
        <Navbar.Text className="ml-3">
          Welcome, {userEmail}
        </Navbar.Text>
      )}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          {!isAuthenticated && (
            <Nav.Link as={Link} to="/login">
              Login
            </Nav.Link>
          )}
          {isAuthenticated && (
            <Nav.Link as={Link} to="/insider-search">
              Insider Search
            </Nav.Link>
          )}
          {isAuthenticated && (
            <Nav.Link onClick={handleLogout} className="ml-auto">
              Logout
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

import React from 'react';

const Logout = () => {
  // Perform logout action (e.g., clear tokens, call an API)

  return (
    <div>
      <h1>You have been logged out.</h1>
    </div>
  );
};

export default Logout;

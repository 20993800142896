import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (emailError || passwordError || email === '' || password === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [email, password, emailError, passwordError]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    setEmailError(!emailRegex.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value.length < 8);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false);
    setLoading(true);
  
    const credentials = btoa(`${email}:${password}`);
    const config = {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    };
  
    try {
      const response = await axios.get('https://api.myinsiderposition.com/authorise_user_bearer', config);
  
      if (response.status === 200 && response.data.success) {
        const authToken = response.headers.Authorization;
        Cookies.set('authenticated', true);
        Cookies.set('authToken', credentials);
        Cookies.set('userEmail', email);
        history.push('/');
      } else {
        setLoginError(true);
      }
    } catch (error) {
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className={styles.loginContainer}>
      <Container className={styles.loginForm}>
        <h2 className="text-center">Login</h2>
        {loginError && (
          <Alert variant="danger">
            Username or password is wrong.
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={emailError}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              isInvalid={passwordError}
            />
            <Form.Control.Feedback type="invalid">
              Password must be at least 8 characters long.
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" disabled={!isFormValid || loading}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"
                />
                <span className="sr-only">Loading...</span> Logging in...
              </>
            ) : (
              'Login'
            )}
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default LoginPage;
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import InsiderSearch from './InsiderSearch';
import InsiderDetails from './InsiderDetails';
import Logout from './Logout';
import ProtectedRoute from './ProtectedRoute';
import styles from './App.module.css';

function App() {
  return (
    <Router forceRefresh={true}>
      <div className={styles.appWrapper}>
        <Header />
        <main className={styles.mainContent}>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/login" component={LoginPage} />
            <ProtectedRoute path="/insider-search" component={InsiderSearch} />
            <Route path="/logout" component={Logout} />
            <Route path="/insider/:id" component={InsiderDetails} />
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';

const Home = () => {
  const isAuthenticated = Cookies.get('authenticated') === 'true';

  return (
    <Container>
      <Row className="justify-content-center mt-4 mb-4">
        <Col md={8} className="text-center">
          <h2>Welcome to My Insider Position platform</h2>
          <p>
            Discover insiders of the project Moony's and learn valuable
            information about employees working in various companies.
          </p>
          {!isAuthenticated && (
            <p>
              To get access, please <Link to="/login" className="styled-link">login</Link> first.
            </p>
          )}
          {isAuthenticated && (
            <p>
              Start exploring with <Link to="/insider-search" className="styled-link">Insider Search</Link>.
            </p>
          )}
        </Col>
      </Row>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src="metraf_moon_as_investigation_skyline_frankfurt_am_main_a5dbdb1f-6026-4bfc-8333-2f019e0a1352.png"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Insider Trading</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src="metraf_a_bid_moon_as_judge_against_an_investment_banker_in_laws_de511a4f-a497-41d8-9e1f-227248e71f85.png"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Company Employees</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src="moony_fra2.png"
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Valuable Information</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default Home;
